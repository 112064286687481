<template>
  <div class="aCustomInput field">
    <label  :for="fieldname">{{label}} <span v-if="isRequired" class="req">*</span></label>
    <textarea
    :id="fieldname"
    :type="inputType"
    v-model="modelValueComputed"
    :placeholder="placeholder"
    :disabled="disabled"
    :class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
    ></textarea>
    <div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
    <div class="helper">{{helper}}</div>
  </div>
</template>

<!-- ========================================= -->

<script>
// Load only a part of lodash to keep the application small
  import { debounce } from "lodash";
  import FormFields from "../mixins/FormFields";
  import dayjs from "dayjs";
  import config from "../config";
  var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
  dayjs.extend(isSameOrBefore)
  export default {
    name: "customInput",

    emit: ["update:modelValue", "update:valueHasChanged", "runCheck"],

    mixins: [FormFields],

    props: {
      inputType:{
        type: String,
        validator : function(value){
          return ["text","password","email","hidden","number","search","tel","url","date"].includes(value);
        }
      },
      dataFrom : {
        type: String,
        required: true
      },
      disabled : {
        type: Boolean,
        required: false,
      default: false
      },
      valueHasChanged: {
        type: String,
        required: false
      },
    },

    data(){
      return {
        errorMessage : "",
        label: "",
        config: config,
      }
    },

    computed: {
      modelValueComputed: {
        get() {
          return this.modelValue;
        },
        set(newVal) {
          this.validateMyselfDebounced();
          this.$emit('update:modelValue', newVal);
        }
      }
    },

    watch: {
    /* Watch locale, not lang, as nl_BE is sometimes different from nl_NL (and fr_BE could be different from fr_LU) */
    '$store.getters.getLocale': function(/* newLocale, oldLocale */){    
      /* Must trigger a refresh of data from here */
      this.getInfosFromApi(this.dataFrom);
    },
  },

  methods : {

    validateMyself() {
     if(this.inputType === "date") {
      return this.dateCheck()
    }else{
      /* The validation is simple: is the field filled? */
      if(this.modelValueComputed.length >= 50 && this.modelValueComputed.length <= 200){
        // console.log(this.modelValueComputed); 
        this.giveSuccess();
        this.$emit('runCheck', {fieldname: this.fieldname, valid: true});

        /* TODO FOR A LIMITED LIST OF VALUES, I CAN USE A CONDITION */
        /* if acceptedValues.includes(inputValue)… */
        /*
        if (this.modelValueComputed === "3060-035" || this.modelValueComputed === "3906-765"){
         this.giveSuccess();
         this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
       }else{
        this.giveError();
        this.errorMessage = this.$t('errors.wrongcnk');
        this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
      }
      */


      }else{
        this.giveError();
        this.errorMessage = this.$t('errors.lengthOfAnswer');
        this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
      }
    }
  },




  getInfosFromApi(df){
   this.setLoading();
   let hdref = config.idlead;
   this.axios.get(`/dropdown/${hdref}/metadata/${df}?lang=${'fr'}`)
   .then(res => {
  // console.log("I got a positive MetaInput result: ");
  // console.log(res.data);
    this.label = res.data.description;

  })
   .catch(error => {
    console.log(`MetaInput reported an error: ${error}`);
    if(error.response.status === 503){
      this.$emit('maintenanceDetected');
    }else{
      this.giveError();
      this.errorMessage = error.response.data.errors;
      this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
    }


  })
   .then(()=>{});
   this.setNotLoading();
 },


/* TODO : add a check: is date inbetween purchase dates from lead */
/* use then this.$t('errors.dateOutOfPeriod'); */
 dateCheck() {
  this.dateMessage = null;
  let date = dayjs(this.modelValueComputed);
  if(!date.isValid()) {
    this.errorMessage = this.$t('errors.dateInvalid');
    this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
  } else if(!date.isSameOrBefore(dayjs())) {
    this.errorMessage = this.$t('errors.dateBefore');
    this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
    this.giveError();
  } else {
    this.giveSuccess();
    this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
  }
},
},

created() {
  this.validateMyselfDebounced = debounce(this.validateMyself, 500);
    // run validation immediately when the value is not null or empty
  if(this.modelValueComputed) {
    this.validateMyselfDebounced();
  }
  this.getInfosFromApi(this.dataFrom);
}
}

</script>

<!-- ========================================= -->

<style lang="scss" scoped>
</style>
