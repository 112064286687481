export default {
	data () {
		return {

			/* For CLIENT in GDPR, see the translation files */

			/* FIRST PAGE */
			/* usual values: 'landing', 'instructions', 'form1' */
			firstPageOfThisForm: 'form1',

			/* For P&G */
			/* If true, allow OneTrust tag and remove GDPR, Matomo, CookieFirst… */
			/* If false… Don't forget to update the IDs and CODES on mixins "ga" and "cf" for google analytics and cookie first */
			isPnGAction: true,

			/* SCRIPTS */
			thereIsFloodLightScriptInThisAction: false,

			/* BREADCRUMBS */
			displayBreadCrumbs: true,
			listOfCrumbsForThisAction: ['store', 'form', 'cashback'],
			 /* INFO Possibles values : 'info', 'registration', 'store', 'form', 'survey', 'summary', 'cashback' */
			
			/* DEFAULT LOCALE */
			/* Useful to force nl_NL instead of nl_BE */
			/* ! See in the i18n.js file */

			/* Allow a coupon in the mail (if survey is filled) */
			aCouponCouldBeSent: false,

			/* Is there a survey at all ?*/
			thereIsASurveyInThisAction: false,

			/* REQUIRED FIELDS to UNLOCK BUTTONS */
			/* List of fields that must be valid for a specific button to unlock */
			mustBeValidForPage: {
				'btn_cashback_on_form1' : ['firstName', 'lastName', 'email', 'IBAN'],
				'btn_cashback_on_form2' : ['idLeadTMD1','file', 'conditions','optin2'],
				'btn_cashback_on_survey': ['idLeadTMD1', 'idLeadTMD2', 'idLeadTMD3'],
			},
			/* REQUIRED FIELDS to UNLOCK THE FORM */
			/* Ultime check to see if something is missing despite the buttons check */
			/* Must never include optionnel answers like survey's */
			allRequiredFields: ['firstName', 'lastName', 'email', 'IBAN', 'idLeadTMD1','file', 'conditions','optin2'],

			/* ALL default fieldname that can be checked
					'idTitle',
					'gender',
					'firstName',
					'lastName',
					'email',
					'phoneNumber1',
					'IBAN', 
					'VATNumber',
					'street1',
					'houseNumber',
					'boxNumber',
					'postalCode',
					'city',
					'countries',
					'selectProduct',
					'purchaseDate',
					'idPOPRetailer',
					'freeRetailerName',
					'idLeadTMD1', 'idLeadTMD2',…
					'serialNumber',
					'file',
					'conditions',

			*/

			/* ! "countries" must not be required when the google API put the value in the field, as it is not validated by action api then. */
			/* TODO: find a way to make action api validate that field */

		}
	},
}
